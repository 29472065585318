<template>
  <section class="message-container">
    <img :src="src" class="mr-2 icon"  /><p class="has-text-weight-semibold">{{ text }}</p> 
    <slot name="custom"/>
  </section>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "warning",
    },
  },
  computed: {
    src() {
      if (this.type === "warning") {
        return require("../assets/warning-message-icon.svg");
      }

      return require("../assets/warning-message-icon.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container{
  background-color: #ffefd666;
  border-radius: 8px;
  padding:12px 8px;
  display: flex;
  color: rgb(232 174 101);
}
</style>